<template>
  <v-container>
    <v-row class="mt-6">
      <v-col>
        <v-card class="mx-auto" v-if="alerts[0]" flat>
          <v-card-title class="">
            <h4 class="font-weight-light">{{ $t("home.allAlert") }}</h4>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text class="py-0">
            <v-timeline dense v-if="alerts[0]">
              <v-slide-x-reverse-transition group hide-on-leave>
                <v-timeline-item
                  v-for="(item, i) in alerts"
                  :key="i"
                  color="teal lighten-1"
                  icon="mdi-information-variant"
                  fill-dot
                >
                  <v-alert text color="primary">
                    <v-row align="center" no-gutters>
                      <v-col class="grow">
                        <h3 class="">{{ item.title }}</h3>
                      </v-col>
                      <v-spacer></v-spacer>

                      <h4 class="font-weight-light" style="direction: ltr">
                        {{ item.datex | moment("YYYY/MM/DD - h:mm a ") }}
                      </h4></v-row
                    >
                    <v-divider
                      class="my-4 primary"
                      style="opacity: 0.22"
                    ></v-divider>

                    <v-row align="center" no-gutters>
                      <v-col style="text-align: justify" class="grow">
                        {{ item.details }}
                      </v-col>
                    </v-row>

                    <v-card-actions
                      v-if="item.attachedFiles && item.attachedFiles.length > 0"
                    >
                      <v-btn depressed @click="setShowAttachments(i)">
                        {{ $t("mail.Attachments") }} ({{
                          item.attachedFiles.length
                        }})
                        <v-icon>
                          {{
                            item.showAttachments
                              ? "mdi-chevron-up"
                              : "mdi-chevron-down"
                          }}</v-icon
                        >
                      </v-btn>
                    </v-card-actions>

                    <v-expand-transition
                      v-if="item.attachedFiles && item.attachedFiles.length > 0"
                    >
                      <div v-show="item.showAttachments">
                        <v-divider></v-divider>

                        <v-card-text>
                          <v-row class="ma-2">
                            <v-col
                              cols="auto"
                              v-for="file in item.attachedFiles"
                              :key="file.name"
                            >
                              <Download-File
                                :iconWidth="50"
                                :width="250"
                                :fileSizeFormat="file.fileSizeFormat"
                                :fileUrl="file.fileUrl"
                                :key="file.name"
                              ></Download-File>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </div>
                    </v-expand-transition>
                  </v-alert>

                  <!-- <v-card outlined>
                    <v-card-title class="teal py-1">
                      <h5 class="white--text font-weight-light">{{ item.title }}</h5>
                      <v-spacer />
                      <h6 class="white--text font-weight-light" style="direction: ltr">
                        {{ item.datex | moment("YYYY/MM/DD - h:mm a ") }}
                      </h6>
                    </v-card-title>

                    <v-card-text class="pt-4">
                      <p>
                        {{ item.details }}
                      </p>
                    </v-card-text>
                  </v-card> -->
                </v-timeline-item>
              </v-slide-x-reverse-transition>
            </v-timeline>
          </v-card-text>
        </v-card>

        <div class="pa-4 text-center" v-else>
          <img width="200px" src="../../assets/notification.svg" />
          <h2 class="py-4 primary2--text">
            {{ $t("home.ThereAreNoNotifications") }}
          </h2>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DownloadFile from "../../components/downloadFile.vue";

export default {
  components: {
    DownloadFile,
  },
  data() {
    return {
      overlay: false,
      loading: false,
      showAttachments: false,
      alerts: [],
    };
  },
  methods: {
    seeMoreFun(i) {
      this.alerts[i].more = !this.alerts[i].more;
      this.overlay = true;
      this.overlay = false;
    },
    setShowAttachments(i) {
      this.alerts[i].showAttachments = !this.alerts[i].showAttachments;
      this.$forceUpdate();
    },
    getAleart() {
      this.overlay = true;
      this.axios
        .get("Alerts/Get?all=true")
        .then((res) => {
          this.alerts = res.data.data;
          for (let i = 0; i < this.alerts.length; i++) {
            this.alerts[i].more = false;
          }
          // console.log(this.alerts);
        })
        .catch((err) => {})
        .finally(() => {
          this.overlay = false;
        });
    },
  },
  created() {
    this.getAleart();
  },
};
</script>

<style></style>
